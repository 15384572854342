import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
    dsn: 'https://98769bbf83fc4dd11fa01af09b270cee@o4508643923066880.ingest.de.sentry.io/4508643930013776',

    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.0,

    // If you don't want to use Session Replay, just remove the line below:
    //integrations: [replayIntegration()],

    beforeSend(event: any) {
        // Clear all cookies from the event object
        if (event.request && event.request.cookies) {
            for (const cookieName in event.request.cookies) {
                if (event.request.cookies.hasOwnProperty(cookieName)) {
                    delete event.request.cookies[cookieName];
                }
            }
        }

        return event;
    },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
